import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { Container, Image } from 'react-bootstrap';
import { IoIosMenu, IoMdClose } from "react-icons/io";
import LogoClear from "assets/images/logo-desktop.png"
import Logo from "assets/images/logoclear.png"
import Line from "assets/images/arrow.svg"
import LineBlue from "assets/images/arrow-blue.svg"
import LINEGREEN from "assets/images/LINE-GREEN.svg"
import EMAILGREEN from "assets/images/line-blue.svg"
import './header.scss'

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const [activeLink, setActiveLink] = useState('');

    return (
        <>
            <header className='header desktop d-flex align-items-center'>
                <Container>
                    <nav>
                        <Link to="/">
                            <h1 className='logo m-0'>
                                <Image className='desktop-logo' width='90' src={LogoClear} />
                                <Image className='mobile-logo' width='70' src={Logo} />
                            </h1>
                        </Link>
                        <IoIosMenu className='toggler' onClick={() => setIsNavOpen(true)} />
                        <div className="navbar-link-wrapper">
                            <a href='#strength_of_visioftask' onClick={() => setActiveLink('strength_of_visioftask')} className={activeLink === 'strength_of_visioftask' ? 'active' : ''}>ビジフォトスクの強み</a>
                            <a href='#achievement' onClick={() => setActiveLink('achievement')} className={activeLink === 'achievement' ? 'active' : ''}>実績</a>
                            <a href='#price' onClick={() => setActiveLink('price')} className={activeLink === 'price' ? 'active' : ''}>料金</a>
                            <a href='#flow' onClick={() => setActiveLink('flow')} className={activeLink === 'flow' ? 'active' : ''}>流れ</a>
                            <a href='#faq' onClick={() => setActiveLink('faq')} className={activeLink === 'faq' ? 'active' : ''}>よくある質問</a>
                            <div className='btn-section-wrapper'>
                                <div className='btn-section'>
                                    <div className='left'>
                                        <Image width='31' height='31' src={LINEGREEN} />
                                        <div className='content'>
                                            <p className='mb-2'>
                                                <span>\</span> まずは無料で <span>/</span>
                                            </p>
                                            <h4>LINEで相談する</h4>
                                        </div>
                                    </div>
                                    <Image src={Line} />
                                </div>
                            </div>
                        </div>
                    </nav>
                </Container>
            </header>
            <div className={isNavOpen ? "navbar-link-wrapper-mobile show" : 'navbar-link-wrapper-mobile'}>
                <IoMdClose onClick={() => setIsNavOpen(false)} />
                <a onClick={() => setIsNavOpen(false)} href='#strength_of_visioftask'>ビジフォトスクの強み</a>
                <a onClick={() => setIsNavOpen(false)} href='#achievement'>実績</a>
                <a onClick={() => setIsNavOpen(false)} href='#price' >料金</a>
                <a onClick={() => setIsNavOpen(false)} href='#flow'>流れ</a>
                <a onClick={() => setIsNavOpen(false)} href='#faq'>よくある質問</a>
                <div className='btn-section-wrapper'>
                    <a className='btn-section email' href='mailto:nakamurafutoshiphoto@gmail.com'>
                        <div className='left'>
                            <div className='white-bg' style={{ backgroundImage: `url(${EMAILGREEN})` }}>
                                <p>EMAIL</p>
                            </div>
                            <div className='content'>
                                <h4>メールで問い合わせ</h4>
                            </div>
                        </div>
                        <Image width={18} height={6} src={LineBlue} />
                    </a>
                    <a className='btn-section' href='https://lin.ee/UPhiCzJ' rel="noreferrer" target='_blank'>
                        <div className='left'>
                            <Image width='31' height='31' src={LINEGREEN} />
                            <div className='content'>
                                <p className='mb-2'>
                                    <span>\</span> まずは無料で <span>/</span>
                                </p>
                                <h4>LINEで相談する</h4>
                            </div>
                        </div>
                        <Image width={18} height={6} src={Line} />
                    </a>
                </div>
            </div>
        </>
    )
}

export default Header