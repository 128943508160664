import Header from "components/Header"
import Footer from "components/Footer"
import AppRoutes from "Routes/index";

function App() {
  return (
    <div className="App">
      <Header />
      <section className="main">
        <AppRoutes />
      </section>
      <Footer />
    </div>
  );
}

export default App;
