import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap'
import LogoClear from "assets/images/logoclear.png"
import Logo from "assets/images/logo.png"
import './footer.scss'

function Footer() {
    const [activeAnchor, setActiveAnchor] = useState('');
    const location = useLocation();
    useEffect(() => {
        setActiveAnchor(location.hash);
    }, [location]);
    return (
        <footer>
            <Container>
                <div className='content-wrapper'>
                    <h1 className='footer-logo'>
                        <Image width='100' src={LogoClear} />
                    </h1>
                    <div className='content'>
                        <p>
                            「ビズフォト」は、プロのカメラマンがビジネスの撮影を行う、新しい形の写真撮影サブスクリプションサービスです。月に一度、ビジネスの成長と進化を撮影し、その魅力と価値を最新の状態に更新します。ビズフォトで、ウェブサイトや広報資料など、各種メディアのビジネスイメージを一新しましょう。
                        </p>
                        <ul className='pt-5 m-0 p-0 w-100'>
                            <li>
                                <a href='#strength_of_visioftask' className={activeAnchor === '#strength_of_visioftask' ? 'active' : ''}>ビジフォトスクの強み</a>
                            </li>
                            <li>
                                <a href='#achievement' className={activeAnchor === '#achievement' ? 'active' : ''}>実績</a>
                            </li>
                            <li>
                                <a href='#price' className={activeAnchor === '#price' ? 'active' : ''}>料金</a>
                            </li>
                            <li>
                                <a href='#flow' className={activeAnchor === '#flow' ? 'active' : ''}>流れ</a>
                            </li>
                            <li>
                                <a href='#faq' className={activeAnchor === '#faq' ? 'active' : ''}>よくある質問</a>
                            </li>
                        </ul>

                        <p className='copyright'>©2023 中村太写真事務所 All rights reserved.</p>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer