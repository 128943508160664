import React, { lazy, Suspense } from 'react';
import Loader from "components/Loader"

const LazyHeroSection = lazy(() => import('components/HeroSection'));
const LazyProblemsList = lazy(() => import('components/ProblemsList'));
const LazyWhatIsVisifoTask = lazy(() => import('components/WhatIsVisifoTask'));
const LazyFirsLimitedCampaign = lazy(() => import('components/FirstLimitedCampaign'));
const LazySuitableForCompanies = lazy(() => import('components/SuitableForCompanies'));
const LazyWhatCanBePhotographed = lazy(() => import('components/WhatCanBePhotographed'));
const LazyVisifotaskStrengths = lazy(() => import('components/VisifotaskStrengths'));
const LazyAchievement = lazy(() => import('components/Achievement'));
const LazyRatePlan = lazy(() => import('components/RatePlan'));
const LazyPhotographerIntro = lazy(() => import('components/PhotographerIntro'));
const LazyUsageFlow = lazy(() => import('components/UsageFlow'));
const LazyFAQ = lazy(() => import('components/FAQ'));
const LazyCTA = lazy(() => import('components/CTA'));
const LazyCompanyProfile = lazy(() => import('components/CompanyProfile'));

function Home() {
    return (
        <Suspense fallback={<div className='loader-wrapper'><Loader /></div>}>
            <LazyHeroSection />
            <LazyProblemsList />
            <LazyWhatIsVisifoTask />
            <LazyFirsLimitedCampaign />
            <LazySuitableForCompanies />
            <LazyWhatCanBePhotographed />
            <LazyVisifotaskStrengths />
            <LazyAchievement />
            <LazyRatePlan />
            <LazyCTA />
            <LazyPhotographerIntro />
            <LazyUsageFlow />
            <LazyFAQ />
            <LazyCTA />
            <LazyCompanyProfile />
        </Suspense>
    );
}

export default Home;
